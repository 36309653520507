import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/templates/content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Callout = makeShortcode("Callout");
const Equation = makeShortcode("Equation");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Classifications of Matter`}</h1>
    <p>{`Matter is typically characterized by: `}</p>
    <ol>
      <li parentName="ol">{`Its physical state (gas, liquid, or solid)`}</li>
      <li parentName="ol">{`Its composition (element, compound, or mixture)`}</li>
    </ol>
    <h2>{`States of Matter`}</h2>
    <p>{`Gas — no fixed volume or shape, and can be compressed or expanded as there is space between the molecules. `}</p>
    <p>{`Liquid — distinct volume but no distinct shape, cannot be compressed. `}</p>
    <p>{`Solid — definite volume and shape, cannot be compressed. `}</p>
    <h2>{`Composition`}</h2>
    <p>{`A `}<strong parentName="p">{`pure substance`}</strong>{` (or `}<strong parentName="p">{`substance`}</strong>{`) is matter that has distinct properties and a composition that does not vary sample to sample. `}</p>
    <p>{`Two types of substances: `}<strong parentName="p">{`elements`}</strong>{` and `}<strong parentName="p">{`compounds`}</strong>{`. `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Elements`}</strong>{` are substances that cannot be decomposed into simpler substances.`}</li>
      <li parentName="ul"><strong parentName="li">{`Compounds`}</strong>{` are substances composed of two or more elements (e.g. water).`}</li>
    </ul>
    <p>{`Most elements can interact with other elements to form `}<strong parentName="p">{`compounds`}</strong>{`. The elemental composition of a compound is always the same; this is called the `}<em parentName="p">{`law of constant composition.`}</em>{` `}</p>
    <p>{`In a `}<strong parentName="p">{`mixture`}</strong>{`, each substance retains its chemical identity and properties. The composition of a mixture may vary in quantities. `}</p>
    <p>{`Homogenous mixtures are also called `}<strong parentName="p">{`solutions`}</strong>{`, even if they're not liquid. `}</p>
    <h1>{`Properties of Matter`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Physical properties`}</strong>{` — can be observed without changing the identity and composition of the substance.`}</li>
      <li parentName="ul"><strong parentName="li">{`Chemical properties`}</strong>{` — describe the way a substance may change to form other substances.`}<ul parentName="li">
          <li parentName="ul">{`For example, flammability.`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Intensive properties`}</strong>{` — do not depend on the amount of sample.`}<ul parentName="li">
          <li parentName="ul">{`These can be used to identify substances.`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Extensive properties`}</strong>{` — depend on the amount of sample.`}<ul parentName="li">
          <li parentName="ul">{`For example, mass and volume.`}</li>
        </ul></li>
    </ul>
    <h1>{`Uncertainty in Measurement`}</h1>
    <h2>{`Definitions`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Exact numbers`}</strong>{` — numbers whose values are known exactly.`}</li>
      <li parentName="ul"><strong parentName="li">{`Inexact numbers`}</strong>{` — numbers whose value have some uncertainty.`}<ul parentName="li">
          <li parentName="ul">{`All numbers obtained from measurement are inexact because of equipment or human errors.`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Precision`}</strong>{` — how closely individual measurements agree with one another.`}</li>
      <li parentName="ul"><strong parentName="li">{`Accuracy`}</strong>{` — how closely individual measurements agree with the true value.`}</li>
    </ul>
    <h2>{`Significant Figures`}</h2>
    <p>{`All digits of a measured quantity, including the uncertain (last) digit, are called `}<strong parentName="p">{`significant figures`}</strong>{`. `}</p>
    <p>{`Determine significant figures by reading a number left to right and counting digits, starting with the first non-zero digit. `}</p>
    <p>{`The zeroes in a number that ends with zeroes but contains no decimal point are assumed to be not significant. `}</p>
    <p>{`However, this can be more clearly expressed with scientific notation:
1.03 `}{`*`}{` 10^4 (three significant digits)
1.0300 `}{`*`}{` 10^4 (five significant digits)`}</p>
    <h2>{`Significant Figures in Calculations`}</h2>
    <Callout mdxType="Callout">
    The final answer should be reported with only one uncertain digit. 
    </Callout>
    <h3>{`Addition and Subtraction`}</h3>
    <p>{`The result has the same number of decimal places as the measurement with the fewest decimal places. `}</p>
    <p>{`Example: 20.42 + 83.1 = 103.52 ⇒ 103.5`}</p>
    <p>{`83.1 only has one decimal place, so the answer must be rounded to one decimal place. `}</p>
    <h3>{`Multiplication and Division`}</h3>
    <p>{`The result contains the same number of significant figures as the measurement with the fewest significant figures. `}</p>
    <p>{`Example: 6.221 * 5.2 = 32.3492 ⇒ 32`}</p>
    <p>{`5.2 only has two significant figures, so the answer must be rounded to two significant figures. `}</p>
    <h1>{`Dimensional Analysis`}</h1>
    <p><strong parentName="p">{`Dimensional analysis`}</strong>{` is the practice of multiplying or dividing units along with the numbers. `}</p>
    <p>{`The key is using conversion factors, such as this one: `}</p>
    <Equation text="\frac{2.54 \, \text{cm}}{1 \, \text{in.}}" mdxType="Equation" />
    <p>{`To calculate the units like this: `}</p>
    <Equation text="(8.50 \, \text{in.}) * \frac{2.54 \, \text{cm}}{1 \, \text{in.}} = 21.6 \, \text{cm}" mdxType="Equation" />
    <p><em parentName="p">{`The inches cancel out on top and bottom leaving just centimeters.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      